import React, { Component } from 'react'
import axios from 'axios';
import ReactFormInputValidation from "react-form-input-validation";

export default class Contact extends Component {
  constructor(props) {
    super(props)

    this.onChangeUserName = this.onChangeUserName.bind(this);
    this.onChangeUserEmail = this.onChangeUserEmail.bind(this);
    this.onChangeUserMessage = this.onChangeUserMessage.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.state = {
      name: null,
      email: null,
      message: null,
      errors: {},
    };

    this.form = new ReactFormInputValidation(this);
    this.form.useRules({
        name: "required",
        email: "required|email",
        message: "required",
    });
  }

  onChangeUserName(e) {
    this.setState({ name: e.target.value })
  }

  onChangeUserEmail(e) {
    this.setState({ email: e.target.value })
  }

  onChangeUserMessage(e) {
    this.setState({ message: e.target.value })
  }

  onSubmit(e) {
    e.preventDefault()

    const userObject = {
      name: this.state.name,
      email: this.state.email,
      message: this.state.message
    };

    // axios.post('https://zeromessagesection.herokuapp.com/users/create', userObject)
    //   .then((res) => {
    //     console.log(res.data)
    //   }).catch((error) => {
    //     console.log(error)
    //   });

    this.setState({ name: '', email: '', message: '' })
  }

  render() {
    return (
      <div className="site-section" id="contact-section" data-aos="fade">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5 order-1 order-md-2 mb-5 mb-md-0">
              <img src="images/undraw_contact_us_15o2.svg" alt="svgicon" className="img-fluid" />
              <div className="wrapper">
                <form onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <label>Name</label>
                    <input name="name" type="text" value={this.state.name} onChange={this.onChangeUserName} onBlur={this.form.handleBlurEvent} data-attribute-name="Name" className="form-control" />
                    <label className="error">
                      {this.state.errors.name ? this.state.errors.name : ""}
                    </label>
                  </div>
                  <div className="form-group">
                    <label>Your Email</label>
                    <input name="email" type="email" value={this.state.email} onChange={this.onChangeUserEmail} onBlur={this.form.handleBlurEvent} data-attribute-name="Email" className="form-control" />
                    <label className="error">
                      {this.state.errors.email ? this.state.errors.email : ""}
                    </label>
                  </div>
                  <div className="form-group">
                    <label>Message</label>
                    <input name="message" type="text" value={this.state.message} onChange={this.onChangeUserMessage} onBlur={this.form.handleBlurEvent} data-attribute-name="Message" className="form-control" />
                    <label className="error">
                      {this.state.errors.message ? this.state.errors.message : ""}
                    </label>
                  </div>
                  <div className="form-group text-center">
                    <input type="submit" value="Submit" className="btn contact-button" />
                  </div>

                </form>
              </div>
            </div>
            <div className="col-md-6 mr-auto order-2 order-md-1">
              <h2 className="section-title mb-3">Contact Us</h2>
              <p className="mb-5">We are here for you.</p>
              <div className="row">
                <ul className="List_Class">
                  <li className='contact-list'>
                    <div className="row">
                      <div className="col center_col">
                        <span className="span_col">
                          <img src="images/gps.png" alt="image_file" className="img-fluid-vector" />
                        </span>
                      </div>
                      <div className="col center_col_float">
                        <span className="span_col" >242/1, Dambahena Road, Maharagama, Sri lanka.</span>
                      </div>

                    </div>
                  </li>
                  <li className='contact-list'>
                    <div className="row">
                      <div className="col center_col">
                        <span className="span_col">
                          <img src="images/phone.png" alt="image_file" className="img-fluid-vector" />
                        </span>
                      </div>
                      <div className="col center_col">
                        <span className="span_col" >+94 75 032 0381</span>
                      </div>

                    </div>
                  </li>
                  <li className='contact-list' >
                    <div className="row">
                      <div className="col center_col">
                        <span className="span_col">
                          <img src="images/whatsapp.png" alt="image_file" className="img-fluid-vector" />
                        </span>
                      </div>
                      <div className="col center_col">
                        <span className="span_col" >+94 77 795 4128</span>
                      </div>

                    </div>
                  </li>
                  <li className='contact-list'>
                    <div className="row">
                      <div className="col center_col">
                        <span className="span_col">
                          <img src="images/mail.png" alt="image_file" className="img-fluid-vector" />
                        </span>
                      </div>
                      <div className="col center_col">
                        <span className="span_col" >info@joytechz.com</span>
                      </div>

                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}
