import React, { Component } from 'react'

export default class Service extends Component {
  render() {
    return (
        <div className="site-section bg-light" id="services-section" data-aos="fade">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-5">
              <h2 className="section-title">Services</h2>
              <p>We are providing various kind of services to you. We have a vast area of web development services as well as custom software solution creation services</p>
            </div>
          </div>
        </div>
        <div className="owl-carousel nonloop-block-14">
          <div className="service">
            <div>
              <span className="custom-icon-wrap"><span className="flaticon-reload" /></span>
              <h3>Branding Design</h3>
              <p>Your brand identity is what makes you instantly recognizable to your customers. And we are here to make your brand. We can build the connection between you and your customer.</p>
            </div>
          </div>
          <div className="service">
            <div>
              <span className="custom-icon-wrap"><span className="flaticon-download" /></span>
              <h3>IOT solutions</h3>
              <p>We offer a complete range of IoT services and customizable solutions. Our team is highly skilled in designing special purpose custom hardware and software designs. </p>
            </div>
          </div>
          <div className="service">
            <div>
              <span className="custom-icon-wrap"><span className="flaticon-monitor" /></span>
              <h3>Web Development</h3>
              <p>We are using latest cutting edge technologies to web development and we are providing most optimized and resource saving custom web solutions. We have highly skilled web development team.</p>
            </div>
          </div>
          <div className="service">
            <div>
              <span className="custom-icon-wrap"><span className="flaticon-chat" /></span>
              <h3>UI/UX Design</h3>
              <p>We are providing single page static web page solutions to our customers as their needs. We are using best designers to design our UI/UX designs.</p>
            </div>
          </div>
          <div className="service">
            <div>
              <span className="custom-icon-wrap"><span className="flaticon-glasses" /></span>
              <h3>Software Development</h3>
              <p>Software development is one of our major roles. We have highly skilled Backend and Frontend developers working with their full capacity. Latest technologies used for every bit of code.</p>
            </div>
          </div>
          <div className="service">
            <div>
              <span className="custom-icon-wrap"><span className="flaticon-vector" /></span>
              <h3>Creative Design</h3>
              <p>We can provide creative designs as per your need. We are designing flyers,Posters,Photos etc. You can select your needs.</p>
            </div>
          </div>
        </div>
      </div>
    )
}
}