import React, { Component } from 'react'
import Axios from 'axios'
export default class Introduction extends Component {

  // async componentDidMount() {
  
  //    await Axios.get(`https://zeromessagesection.herokuapp.com`)
    
  // }



  render() {
    return (
     
        <div>
        <div className="site-mobile-menu site-navbar-target">
          <div className="site-mobile-menu-header">
            <div className="site-mobile-menu-close mt-3">
              <span className="icon-close2 js-menu-toggle" />
            </div>
          </div>
          <div className="site-mobile-menu-body" />
        </div>
        <header className="site-navbar js-sticky-header site-navbar-target" role="banner">
          <div className="container-fluid" style={{paddingLeft: '2rem', paddingRight: '3rem'}}>
            <div className="d-flex align-items-center">
              <div className="site-logo" ><a href="index.html" className="text-uppercase">
                  <img className="Logo_Company" alt="logo" src="images/joytechzLogo.png" />
                </a></div>
              <div>
                <nav className="site-navigation position-relative text-right" role="navigation">
                  <ul className="site-menu main-menu js-clone-nav mr-auto d-none d-xl-block">
                    <li><a href="#home-section" className="nav-link">Home</a></li>
                    <li><a href="#background-section" className="nav-link">Background</a></li>
                    <li><a href="#delivery-section" className="nav-link">Deliveries</a></li>
                    <li><a href="#process-section" className="nav-link">Process</a></li>
                    <li><a href="#services-section" className="nav-link">Services</a></li>
                  </ul>
                </nav>
              </div>
              <div className="ml-auto">
                <nav className="site-navigation position-relative text-right" role="navigation">
                  <ul className="site-menu main-menu site-menu-dark js-clone-nav mr-auto d-none d-xl-block">
                    
                    <li className="cta"><a href="#contact-section" className="nav-link"><span className="border rounded text-white contact-button">Contact</span></a></li>
                  </ul>
                </nav>
                <a href="#" className="d-inline-block d-xl-none site-menu-toggle js-menu-toggle float-right"><span className="icon-menu h3" /></a>
              </div>
            </div>
          </div>
        </header>
      </div>
    )
  }
}
