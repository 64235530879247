import React, { Component } from 'react'

export default class Approach extends Component {
  render() {
    return (
      <div className="site-section section-2 bg-light" id="process-section" data-aos="fade">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-5">
              <h2 className="section-title">Our Approach</h2>
              <p>Every project is unique, but they all start with one thing in common. We want to know everything: where you started. Where you want to go next. What you value. Who your customers are and why they care about you. The only way to solve a problem is to understand it from every angle. Luckily, we’ve got a proven process to quench our insatiable curiosity.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
              <div className="process bg-white rounded">
                <div>
                  <span className="flaticon-glasses display-4 text-primary mb-4 d-inline-block" />
                  <h3>Data Gathering</h3>
                  <p>Data collection is the process of gathering quantitative and qualitative information on specific variables with the aim of evaluating outcomes or gleaning actionable insights. Good data collection requires a clear process to ensure the data you collect is clean, consistent, and reliable.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
              <div className="process bg-white rounded">
                <div>
                  <span className="flaticon-vector display-4 text-primary mb-4 d-inline-block" />
                  <h3>Implementation</h3>
                  <p>We have well experienced and highly skilled development team. They can assure 100% quality and delivery of unique designs. We are working as a team to provide the best coding and design to you. In the implementation process we are monitoring the functions of the website as well as the security of the web site.</p>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
              <div className="process bg-white rounded">
                <div>
                  <span className="flaticon-monitor display-4 text-primary mb-4 d-inline-block" />
                  <h3>Launch</h3>
                  <p>We have many experiences with different kind of hosting and domain name services. We wish to provide the best service while we are launching your product. Mostly we are using cloud based services to deploy the web App. And also the best security providers.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>)
  }
}