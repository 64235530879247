import React, { Component } from 'react'

export default class Timeline extends Component {
  render() {
    return (
      <div className="intro-section custom-owl-carousel" id="home-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 mr-auto" data-aos="fade-up">
            <div className="owl-carousel slide-one-item-alt-text">
              <div className="slide-text">
                <h1>We Do Awesome Things</h1>
                <p className="mb-5">The result-oriented process at Joytechz Solutions, helps develop top-notch.</p>
                <p><a href="#" target="_blank" className="btn btn-outline-light py-3 px-5">Get Started</a></p>
              </div>
              <div className="slide-text">
                <h1>Welcome</h1>
                <p className="mb-5">Smart and effective strategy guaranteeing real,measurable results for your business</p>
                <p><a href="#" target="_blank" className="btn btn-outline-light py-3 px-5">Get Started</a></p>
              </div>
              <div className="slide-text">
                <h1>High Quality Website Designs</h1>
                <p className="mb-5">Living in the digital age, a website is now an essential for a business, big or small.</p>
                <p><a href="#" target="_blank" className="btn btn-outline-light py-3 px-5">Get Started</a></p>
              </div>
              <div className="slide-text">
                <h1>We do things right.</h1>
                <p className="mb-5">We understand that each business has its unique objectives. And in order to leverage online resources to achieve those objectives, it’s essential to have a carefully planned and well executed Digital marketing strategy that connect the specific business &amp; market requirements.</p>
                <p><a href="#" target="_blank" className="btn btn-outline-light py-3 px-5">Get Started</a></p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ml-auto" data-aos="fade-up" data-aos-delay={100}>
            <div className="owl-carousel slide-one-item-alt">
              <img src="images\svg1.png" alt="Image" className="img-fluid" />
              <img src="images\svg2.png" alt="Image" className="img-fluid" />
              <img src="images\svg3.png" alt="Image" className="img-fluid" />
              <img src="images\svg4.png" alt="Image" className="img-fluid" />
            </div>
            <div className="owl-custom-direction">
              <a href="#" className="custom-prev"><span className="icon-keyboard_arrow_left" /></a>
              <a href="#" className="custom-next"><span className="icon-keyboard_arrow_right" /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
  }
}
