import React, { Component } from 'react'

export default class Projects extends Component {
  render() {
    return (
		<div className="site-section section-1" id="background-section" data-aos="fade">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 mr-auto mb-5" data-aos="fade-up">
              <div className="mb-5">
                <h2 className="section-title">Fuelled by Powerful Technology!</h2>
                <p>We are providing the best web based solutions using cutting edge technologies. A professional website has a far wider reach than any other form of advertising. We understand that the choice of accurate graphics, layout and utilization of colors to match the business & the industry are essential in establishing the brand image of the company via the official website.</p>
                <p className="mb-5">In order to provide the maximum effect to the customers we assure you a latest technology used web site which can be scalable. Our development team has many experience building standard web solutions. And we have the best people.</p>
                <ul className="ul-check list-unstyled success">
                  <li>24/7 up-time Guaranteed.</li>
                  <li>100% Customer satisfaction.</li>
                  <li>Optimized Performance.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-6" data-aos="fade-up" data-aos-delay={100}>
              <div className="image-absolute-box">
                <div className="box">
                  <div className="icon-wrap"><span className="flaticon-vector" /></div>
                  <h3>Pixel Perfect Finish</h3>
                  <p>We offers best web design solutions for small & medium businesses and corporate as well. Web design Sri Lanka offers huge range of web design services it means website domains and everything in between.</p>
                  
                </div>
                <img src="images/technologies.png" alt="Image" className="img-fluid" />
                <img src="images/svg1.png" alt="Image" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
