import React, { Component } from 'react'
import Fade from 'react-reveal/Fade';
export default class Blog extends Component {
  render() {
    return (
		<div className="site-section section-2" id="delivery-section" data-aos="fade">
        <div className="container">
          <div className="row">
			  
            <div className="col-lg-6 mb-5">
              <h2 className="section-title">What you will get</h2>
            </div>
          </div>
          <div className="row">

            <div className="col">
				<Fade left>
				<div style={{marginTop: '40%'}}>
                <h3>Strategy Driven</h3>
                <p>Strategy comes first! Strategic plan is a constitution of any organization. Strategy should be centered then we should develop other components around it. And at the end, all should be aligned.</p>
              </div>
				</Fade>
              
            </div>

            <div className="col media_display">
				<Fade right>
				<img src="images/undraw_design_objectives_fy1r.svg" alt="Image" className="img-fluid" />
				</Fade>
             
            </div>
          </div>
          <div className="row">
            <div className="col media_display">
				<Fade left>
				<img src="images/undraw_mobile_web_2g8b.svg" alt="Image" className="img-fluid" />
				</Fade>
              
            </div>
            <div className="col">
			<Fade right>
			<div style={{marginTop: '40%'}}>
                <h3>100% Mobile Responsive</h3>
                <p>Mobile-friendly design helps you connect with customers everywhere. We are providing best Mobile-friendly designs with 100% accuracy.</p>
              </div>
			</Fade>
              
            </div>
          </div>
          <div className="row">
            <div className="col">
			<Fade left>
			<div style={{marginTop: '40%'}}>
                <h3>Result Focused Content</h3>
                <p>Regardless of business or industry, audiences expect engaging, useful content tailored to their needs. They will quickly dismiss content (and an organization or source that produces it) that is irrelevant, poorly executed, or disingenuous.</p>
              </div>
			</Fade>
              
            </div>
            <div className="col media_display">
			<Fade right>
			<img src="images/undraw_project_completed_w0oq.svg" alt="Image" className="img-fluid" />
			</Fade>
              
            </div>
          </div>
          <div className="row">
            <div className="col media_display">
				<Fade left>
				<img src="images/undraw_programmer_imem.svg" alt="Image" className="img-fluid" />
				</Fade>
              
            </div>
            <div className="col">
				<Fade right>
				<div style={{marginTop: '40%'}}>
                <h3>Unlimited Tech Support</h3>
                <p>A better tech support experience. Satisfaction guaranteed - or it’s on us! It’s simple. If we don’t fix it, you don’t pay.</p>
              </div>
				</Fade>
             
            </div>
          </div>
        </div>
      </div>
    )
  }
}
