import React, { Component } from 'react';
import './App.css';
import Sidebar from './components/sidebar'
import Introduction from './components/introduction'
import About from './components/about'
import Timeline from './components/timeline'
import Counter from './components/counter'

import Projects from './components/projects'
import Blog from './components/blog'
import Approach from './components/approach'
import Service from './components/service'
import Contact from './components/contact'
import Pricing from './components/pricing'
class App extends Component {
  
  render() {
    return (
      <div id="colorlib-page">
        <div id="container-wrap">
         	
				<div id="colorlib-main">
					<Introduction></Introduction>
		<Timeline></Timeline>
        <Projects></Projects>
        <Counter></Counter>
        <Blog></Blog>
        <Approach></Approach>
       
        <Service></Service>
        <Contact></Contact>
        <About></About>
     
          	</div>
      	</div>
      </div>
    );
  }
 
}

export default App;
