import React, { Component } from 'react'

export default class Counter extends Component {
  render() {
    return (
  
      <div className="container section-counter">
      <div className="row">
        <div className="col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay>
          <div className="counter d-flex align-items-start mb-5">
            <div className="icon-wrap"><span className="flaticon-reload text-primary" /></div>
            <div className="counter-text">
              <strong className="number" data-number={360}>0</strong>
              <span>Creativity</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay={100}>
          <div className="counter d-flex align-items-start  mb-5">
            <div className="icon-wrap"><span className="flaticon-download text-primary" /></div>
            <div className="counter-text">
              <strong className="number" data-number={2000}>0</strong>
              <span>Coding Hours </span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay={200}>
          <div className="counter d-flex align-items-start mb-5">
            <div className="icon-wrap"><span className="flaticon-monitor text-primary" /></div>
            <div className="counter-text">
              <strong className="number" data-number={120}>0</strong>
              <span>Coffee Cups</span>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3" data-aos="fade-up" data-aos-delay={300}>
          <div className="counter d-flex align-items-start">
            <div className="icon-wrap"><span className="flaticon-chat text-primary" /></div>
            <div className="counter-text">
              <strong className="number" data-number={250}>0</strong>
              <span>Tech Talks</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    )
}
}
